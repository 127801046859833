import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import styles from './index.module.css';

function Welcome() {
  return (
    <div className={styles.welcome}>
        <Helmet>
            <title>Lossless Wax</title>
        </Helmet>
        <section className={styles.quote}>
            <h2>the intersection of minimalism and maximalism is</h2>
            <div className={styles.curationWrapper}>
                <h2 className={styles.typewriter}>curation</h2>
            </div>
        </section>
        <section className={styles.menu}>
            <Grid container>
                <Grid item xs={12} md={12} className={styles.item}>
                    <a href='https://www.eventbrite.com/e/lossless-wax-good-question-lebanon-relief-fundraiser-tickets-1034378633337?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl' target='_blank' rel='noreferrer'>
                        <h4>Lossless Wax x Good Question:<br />Lebanon Relief Fundraiser</h4>
                    </a>
                </Grid>
                <Grid item xs={12} md={4} className={styles.item}>
                    <a href='/sets'>
                        <h4>sets</h4>
                    </a>
                </Grid>
                {/* <Grid item xs={12} md={6} className={styles.item}>
                    <a href='/shows'>
                        <h4>upcoming shows</h4>
                    </a>
                </Grid> */}
                <Grid item xs={12} md={4} className={styles.item}>
                    <a href='https://soundcloud.com/losslesswax' target='_blank' rel='noreferrer'>
                        <h4>soundcloud</h4>
                    </a>
                </Grid>
                <Grid  item xs={12} md={4} className={styles.item}>
                    <a href='/the-master'>
                        <h4>sign up</h4>
                    </a>
                </Grid>
                {/* <Grid item xs={12} md={4} className={styles.item}>
                    <a href='/albums'>
                        <h4>curated albums</h4>
                    </a>
                </Grid> */}
                {/* <Grid  item xs={12} md={3} className={styles.item}>
                    <a href='/about'>
                        <h4>our story</h4>
                    </a>
                </Grid>
                <Grid  item xs={12} md={3} className={styles.item}>
                    <a href='/contact'>
                        <h4>get involved</h4>
                    </a>
                </Grid> */}
            </Grid>
        </section>
    </div>
  )
}
export default Welcome;